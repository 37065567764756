import Image from 'next/future/image'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Router from 'next/router'
import Link from 'next/link'
import styled, { createGlobalStyle } from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import { useSelector, useDispatch } from 'react-redux'
import { getAndSetMemberInfo, getCartLength } from '../redux/actions'
import getCookie from '../utils/getCookie'
import setCookie from '../utils/setCookie'
import { signOut } from 'next-auth/react'
import CTAModal from '../components/CTAModal'
import media from '../styles/media'
import Hamburger from './Hamburger'

import { getCategories } from '../service/contentfulAPIs'

const CART_EXPIRES_DAYS = 14
const headerBreakpoint = media.md

export default function HeaderComponent(props) {
  const [categories, setCategories] = useState([])
  const [showMenu, setShowMenu] = useState(false)
  const dispatch = useDispatch()
  const router = useRouter()

  const handleCategoryClick = async (categorySlug) => {
    setShowMenu(false)
    Router.push(`/categories/${categorySlug}`)
  }

  const cartLength = useSelector((state) => {
    return state.order.cartLength
  })

  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    const handleCart = async () => {
      let cartUuid = getCookie('cartUuid')
      if (!cartUuid) {
        const uuid = uuidv4()
        cartUuid = uuid
      }
      const { cartUuid: newCartUuid } = await getCartLength(dispatch)(cartUuid)
      if (newCartUuid) {
        setCookie('cartUuid', newCartUuid, CART_EXPIRES_DAYS)
      }
    }
    handleCart()

    // TODO: why comment this before?
    getAndSetMemberInfo(dispatch)()
  }, [dispatch])

  useEffect(() => {
    const getAndSetCategories = async () => {
      const categories = await getCategories()
      const availableCategories = categories.filter((category) => !category.isHidden)
      setCategories(availableCategories)
    }
    getAndSetCategories()
  }, [])

  useEffect(() => {
    setShowMenu(false)
  }, [router.pathname])

  const memberInfo = useSelector((state) => {
    return state.member.memberInfo
  })

  const handleSignOut = async () => {
    setCookie('cartUuid', '', CART_EXPIRES_DAYS)
    signOut()
  }

  const handleSignIn = () => {
    setShowMenu(false)
    Router.push('/auth/sign-in')
  }

  const navigate = (path) => {
    Router.push(path)
    setShowMenu(false)
  }

  return (
    <>
      <Header>
        <GlobalStyles showMenu={showMenu} />
        <CTAModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
        <LeftHeader>
          <Logo src="/images/logo_stickerHD.svg" alt="StickerHD logo" onClick={() => Router.push('/')} />
          <MakingStickerButton className="bg_successDarkmode_white" onClick={() => setModalOpen(true)}>
            <span>開始製作</span>
            <Image src="/images/cursor.svg" alt="cursor" width={17} height={16} />
          </MakingStickerButton>
          {/*
            <span style={{ marginRight: '32px' }} onClick={() => Router.push('/gallery')} >貼紙作品參考</span>
            */}
          <a href="https://medium.com/stickerhd-%E8%B2%BC%E7%B4%99%E8%A3%BD%E4%BD%9C%E7%A7%98%E8%BE%9B" target="_blank" rel="noreferrer">
            <span style={{ marginRight: '32px' }}>貼紙設計與應用</span>
          </a>
          <span style={{ marginRight: '32px' }} onClick={() => Router.push('/faq')}>
            常見印刷問題
          </span>
        </LeftHeader>

        <RightHeader>
          <CartIcon
            onClick={() => {
              Router.push('/cart')
            }}
          >
            <Image src="/images/cart.svg" alt="cart" width={16} height={15.05} />
          </CartIcon>
          {cartLength}
          {memberInfo.email ? (
            <AvatarContainer>
              <Avatar image={memberInfo.image || '/images/default_avatar.png'} />
              <MenuContainer>
                <Menu>
                  <Info>
                    <div className="avatar-container">
                      <Image src={memberInfo.image || '/images/default_avatar.png'} alt="avatar" width={48} height={48} />
                    </div>
                    <div>
                      <h4>{memberInfo.name}</h4>
                      <p>折現點數：{memberInfo.points} 點</p>
                    </div>
                  </Info>
                  <Links>
                    <Link href="/profile">會員資料設定</Link>
                    <Link href="/profile?to=orders">訂單紀錄與狀態</Link>
                    <Link href="/profile?to=reOrder">追加過往品項</Link>
                    <Link href="/profile?to=quotations">報價單紀錄</Link>
                    <Link href="/profile?to=pointRecords">點數紀錄</Link>
                  </Links>
                  <Logout onClick={handleSignOut}>登出</Logout>
                </Menu>
              </MenuContainer>
            </AvatarContainer>
          ) : (
            <LoginLinkContent
              onClick={() => {
                Router.push('/auth/sign-in')
              }}
              className="bg_primary_white"
            >
              <Image src="/images/login.svg" alt="login icon" fill={true} />
              <span>登入/註冊</span>
            </LoginLinkContent>
          )}
        </RightHeader>

        <MobileHeader>
          <Hamburger className={showMenu ? `active` : ``} onClick={() => setShowMenu(!showMenu)} />
          <MobileLogo src="/images/logo_stickerHD.svg" alt="StickerHD logo" onClick={() => navigate('/')} />
          <MobileCart
            src="/images/cart.svg"
            onClick={() => {
              navigate('/cart')
            }}
          />
        </MobileHeader>
      </Header>
      {showMenu && (
        <MobileMenu>
          <div>
            <Categories>
              {categories.map((category) => (
                <Category key={category.id} onClick={() => handleCategoryClick(category.slug)}>
                  <Image src={`https:${category.thumbnail}`} alt={category.name} width={70} height={70} />
                  <span>{category.name}</span>
                </Category>
              ))}
            </Categories>
            <Profile>
              {memberInfo.email ? (
                <>
                  <MobileInfo>
                    <div className="avatar-container">
                      <Image src={memberInfo.image || '/images/default_avatar.png'} alt="avatar" width={48} height={48} />
                    </div>
                    <div>
                      <h4>{memberInfo.name}</h4>
                      <p>折現點數：{memberInfo.points} 點</p>
                    </div>
                  </MobileInfo>
                  <MobileLinks>
                    <MobileLink href="/profile">
                      <div className="icon-container">
                        <Image src="/images/mobileMenu/profile.svg" alt="user profile" width={14} height={14} />
                      </div>
                      會員資料設定
                    </MobileLink>
                    <MobileLink href="/profile?to=orders">
                      <div className="icon-container">
                        <Image src="/images/mobileMenu/order.svg" alt="orders" width={14} height={14} />
                      </div>
                      訂單紀錄與狀態
                    </MobileLink>
                  </MobileLinks>
                </>
              ) : (
                <>
                  <LoginLinkContent onClick={handleSignIn} className="bg_primary_white">
                    <div className="icon-container">
                      <Image src="/images/login.svg" alt="login icon" width={14} height={14} />
                    </div>
                    <span>登入/註冊</span>
                  </LoginLinkContent>
                </>
              )}
            </Profile>
            <Others>
              <MobileLinks>
                <MobileLink href={`/categories/${props.sampleID}`}>
                  <div className="icon-container">
                    <Image src="/images/mobileMenu/sample.svg" alt="sample" width={14} height={14} />
                  </div>
                  取得紙樣
                </MobileLink>
                <MobileLink href="/faq">
                  <div className="icon-container">
                    <Image src="/images/mobileMenu/faq.svg" alt="FAQ" width={14} height={14} />
                  </div>
                  常見問題
                </MobileLink>
                <MobileLink target="_blank" href="https://medium.com/stickerhd-%E8%B2%BC%E7%B4%99%E8%A3%BD%E4%BD%9C%E7%A7%98%E8%BE%9B">
                  <div className="icon-container">
                    <Image src="/images/mobileMenu/blog.svg" alt="blog" width={14} height={14} />
                  </div>
                  部落格
                </MobileLink>
              </MobileLinks>
            </Others>
            {memberInfo.email && (
              <MobileLogout onClick={handleSignOut}>
                <div className="icon-container">
                  <Image src="/images/mobileMenu/logout.svg" alt="logout icon" width={14} height={14} />
                </div>
                登出
              </MobileLogout>
            )}
          </div>
        </MobileMenu>
      )}
    </>
  )
}

const GlobalStyles = createGlobalStyle`
  ${(props) =>
    props.showMenu &&
    `
      body{
        overflow: hidden;
      }
    `}
`

const Header = styled.header`
  height: 72px;
  // background: #91DB6A;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);
  position: sticky;
  top: 0;
  z-index: 99999;
  ${headerBreakpoint`
      height: auto;
      padding: 15px;
    `}
`
const LeftHeader = styled.div`
  display: flex;
  align-items: center;
  > * {
    cursor: pointer;
  }
  ${headerBreakpoint`
      display: none;
    `}
`
const Logo = styled.img`
  width: 161px;
  margin-right: 40px;
`
const MakingStickerButton = styled.button`
  border-radius: 24px;
  border: none;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);

  height: 46px;
  padding: 0px 22px;
  font-size: 14px;
  letter-spacing: 0.25px;
  font-weight: 500;

  display: flex;
  align-items: center;
  margin-right: 16px;
  span {
    margin-right: 12px;
  }
`

const RightHeader = styled.div`
  display: flex;
  align-items: center;
  > * {
    cursor: pointer;
  }
  ${headerBreakpoint`
      display: none;
    `}
`
const CartIcon = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 8px;
  border-radius: 99px;
  border: 2px solid var(--primary);

  display: flex;
  justify-content: center;
  align-items: center;

  display: flex;
  justify-content: center;
  align-items: center;
`
const AvatarContainer = styled.div`
  position: relative;
  &:hover {
    & > div {
      display: block;
    }
  }
`
const Avatar = styled.div`
  width: 46px;
  height: 46px;
  margin-left: 8px;
  border-radius: 24px;
  background-image: url(${(props) => props.image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
`
const MenuContainer = styled.div`
  z-index: 999999;
  padding-top: 4px;
  position: absolute;
  transform: translate(0px, calc(100%));
  right: 0;
  bottom: 0;
  display: none;
`
const Menu = styled.div`
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);
  border: 1px solid var(--grayInput);
  border-radius: 8px;
  padding: 12px;
  width: auto;
  white-space: nowrap;
  min-width: 240px;
`
const Info = styled.div`
  display: flex;
  align-items: center;
  .avatar-container {
    border-radius: 48px;
    margin-right: 8px;
    overflow: hidden;
  }
  h4 {
    font-size: 18px;
    line-height: 27px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 400;
    color: var(--grayAsh);
  }
  p {
    font-size: 12px;
    line-height: 18px;
    color: var(--grayPlaceholder);
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 600;
  }
  padding-bottom: 12px;
  border-bottom: 1px solid var(--grayInput);
`
const Links = styled.div`
  padding: 12px 0px;
  a {
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    display: block;
    color: var(--grayAsh);
    &:not(:first-of-type) {
      margin-top: 12px;
    }
    &:hover {
      color: var(--primaryDarkmode);
    }
  }
  border-bottom: 1px solid var(--grayInput);
`
const Logout = styled.span`
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  display: block;
  color: var(--grayAsh);
  margin-top: 12px;
  &:hover {
    color: var(--primaryDarkmode);
  }
`
const LoginLinkContent = styled.div`
  height: 46px;
  padding: 0px 16px;
  border-radius: 24px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);

  margin-left: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  span {
    margin-left: 12px;
  }
  ${headerBreakpoint`
      margin-left: 0px;
    `}
`
const MobileHeader = styled.div`
  display: none;
  ${headerBreakpoint`
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    `}
`
const MobileLogo = styled.img`
  height: 20px;
  width: auto;
`
const MenuToggle = styled.img`
  width: 24px;
  height: 24px;
`
const MobileCart = styled(MenuToggle)``
const MobileMenu = styled.div`
  position: fixed;
  width: 100vw;
  height: calc(100vh - 54px);
  left: 0;
  background-color: #fff;
  z-index: 8888;
  padding: 0px 15px;
  overflow: auto;
`
const Categories = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 20px 8px;
  column-gap: 16px;
  row-gap: 16px;
  border-bottom: 1px solid var(--grayInput);
`
const Profile = styled.div`
  padding 20px 8px;
  border-bottom: 1px solid var(--grayInput);
`
const MobileInfo = styled(Info)`
  .avatar-container {
    border-radius: 48px;
    margin-right: 8px;
    overflow: hidden;
  }
  border-bottom: 0px;
  padding-bottom: 0px;
  margin-bottom: 20px;
`
const MobileLinks = styled(Links)`
  border-bottom: 0px;
  padding: 0px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 16px;
  column-gap: 16px;
  a:not(:first-of-type) {
    margin-top: 0px;
  }
`
const MobileLink = styled.a`
  .icon-container {
    display: flex !important;
    align-items: center;
    margin-right: 4px;
  }
  display: flex !important;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`
const Others = styled(Profile)``
const Category = styled.div`
  span {
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    margin-top: 8px;
    text-align: center;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`
const MobileLogout = styled(Logout)`
  .icon-container {
    display: flex !important;
    align-items: center;
    margin-right: 4px;
  }
  width: 100%;
  display: flex !important;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 40px;
  justify-content: center;
`
