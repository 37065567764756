import simpleFetchAPI from '../utils/simpleFetchAPI'

const baseUrl = process.env.contentfulUrl
const accessToken = process.env.contentfulAccessToken

const getEntryEndpoint = (entryId) => {
  const url = `${baseUrl}/entries/${entryId}?access_token=${accessToken}`
  return url
}

const getEntriesEndpoint = (params = {}) => {
  let url = `${baseUrl}/entries?access_token=${accessToken}`

  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined) {
      url += `&${key}=${encodeURIComponent(value)}`
    }
  })

  return url
}

const getAssetEndpoint = (assetId) => {
  const url = `${baseUrl}/assets/${assetId}?access_token=${accessToken}`
  return url
}

const getAssetsEndpoint = (limit = 300) => {
  const url = `${baseUrl}/assets?access_token=${accessToken}&limit=${limit}`
  return url
}

const getResListByIds = async (iterable) => {
  const ids = iterable.map((e) => e.sys.id)

  const tempList = ids.map(async (id) => {
    const apiUrl = getEntryEndpoint(id)
    const res = await simpleFetchAPI(apiUrl)

    return res
  })

  return await Promise.all(tempList).then((values) => values)
}

export { getEntryEndpoint, getEntriesEndpoint, getAssetEndpoint, getAssetsEndpoint, getResListByIds }
