import styled from 'styled-components'
import media from '../../styles/media'

const SmallButtonStyle = `
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  padding: 12px 22px;

  color: #FFF;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 48px;
  cursor: pointer;
`

const SmallButton = styled.button`
  ${SmallButtonStyle}
`

const SmallRedButton = styled(SmallButton)`
  background-color: var(--successDarkmode);
  ${(props) =>
    props.disabled &&
    `
        box-shadow: none;
        opacity: .5;
        cursor: not-allowed;
      `}
`
const SmallGreenButton = styled(SmallButton)`
  background-color: var(--primary);
  ${(props) =>
    props.disabled &&
    `
        box-shadow: none;
        opacity: .5;
        cursor: not-allowed;
      `}
  ${media.sm`
      padding: 4px 12px;
      font-size: 12px;
    `}
`

const SmallGreenButtonDiv = styled.div`
  ${SmallButtonStyle}
  background-color: var(--primary);
  display: inline-block;
  ${(props) =>
    props.disabled &&
    `
        box-shadow: none;
        opacity: .5;
        cursor: not-allowed;
      `}
  ${media.sm`
      padding: 4px 12px;
      font-size: 12px;
    `}
`

const SmallButtonDiv = styled.div`
  ${SmallButtonStyle}
`

const SmallGreenButtonWithIcon = styled(SmallButtonDiv)`
  background: var(--primary);
  padding: 12px 22px;

  display: flex;
  align-items: center;

  img {
    margin-right: 12px;
  }
  ${media.sm`
      padding: 4px 12px;
      font-size: 12px;
    `}
`

export { SmallRedButton, SmallGreenButton, SmallGreenButtonWithIcon, SmallGreenButtonDiv }
