import styled from 'styled-components'
import media from '../../styles/media'

const GreenOutlineButton = styled.button`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  padding: 12px 22px;

  color: var(--primary);
  background: transparent;
  border: 2px solid var(--primary);
  border-radius: 24px;
  cursor: pointer;
  &:hover {
    background-color: var(--grayInput);
  }
  &:active {
    background-color: var(--grayInput);
    box-shadow: inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  ${media.sm`
      padding: 4px 12px;
      font-size: 12px;
    `}
  ${(props) =>
    props.icon
      ? `   
        padding: 12px 22px 12px 50px;
        background: url(${props.icon});
        background-repeat: no-repeat;
        background-position: 22px center;
        background-size: 16px 16px;
      `
      : ''}
  ${(props) =>
    props.small
      ? `
        padding: ${props.icon ? '6px 16px 6px 38px' : '6px 16px'};
        background-position: 16px center;
      `
      : ''}
  ${(props) =>
    props.disabled &&
    `
        box-shadow: none;
        opacity: .5;
        cursor: not-allowed;
      `}
`

export default GreenOutlineButton
