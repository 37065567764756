import { useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { SmallGreenButton } from './Buttons/SmallButtons'
import GreenOutlineButton from './Buttons/GreenOutlineButton'

export default function ScrollableModal({
  modalOpen, // Declare modal state from parent and pass to component via here
  setModalOpen = null,
  closeWhileClickOutside = false,
  fixedHeader = null,
  fixedFooter = null,
  disabledActions,
  children,
  ...props // The component's style will apply to ModalContainer
}) {
  const handleClickOutside = () => {
    if (closeWhileClickOutside) {
      handleClose()
    }
  }

  const handleClose = () => {
    if (setModalOpen !== null) {
      setModalOpen(false)
    }
  }

  return (
    <AnimatePresence>
      <GlobalStyles modalOpen={modalOpen} />
      {modalOpen && (
        <ModalWrapper
          onClick={() => handleClickOutside()}
          key="CustomModal"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <ModalContainer onClick={(e) => e.stopPropagation()} {...props}>
            {Boolean(fixedHeader) && (
              <Header>
                <div>
                  <HeaderTitle>{fixedHeader.title}</HeaderTitle>
                  {Boolean(fixedHeader.description) && <HeaderDescription>{fixedHeader.description}</HeaderDescription>}
                </div>
                {fixedHeader.hasCloseBtn && <CloseBtn src="/images/close.svg" onClick={handleClose} />}
              </Header>
            )}
            <ContentWrapper>
              <Content>{children}</Content>
            </ContentWrapper>
            {Boolean(fixedFooter) && (
              <Footer>
                {fixedFooter.map((btn) =>
                  btn.type == 'primary' ? (
                    <SmallGreenButton onClick={btn.onClick} disabled={disabledActions}>
                      {btn.title}
                    </SmallGreenButton>
                  ) : (
                    <GreenOutlineButton onClick={btn.onClick} disabled={disabledActions}>
                      {btn.title}
                    </GreenOutlineButton>
                  ),
                )}
              </Footer>
            )}
          </ModalContainer>
        </ModalWrapper>
      )}
    </AnimatePresence>
  )
}

const GlobalStyles = createGlobalStyle`
  ${(props) =>
    props.modalOpen &&
    `
      body{
        overflow: hidden;
      }
    `}
`
const ModalWrapper = styled(motion.div)`
  opacity: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
`
const ModalContainer = styled.div`
  width: 600px;
  height: auto;
  background-color: #fff;
  border-radius: 4px;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
const ContentWrapper = styled.div`
  height: 100%;
  overflow: auto;
`
const Content = styled.div`
  padding: 24px 16px 48px 16px;
`
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--grayLine);
  padding: 16px;
`
const HeaderTitle = styled.h4`
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 18px;
  line-height: 34px;
  color: var(--grayAsh);
`
const HeaderDescription = styled.p`
  margin-top: 4px;
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 24px;
`
const CloseBtn = styled.img`
  cursor: pointer;
`
const Footer = styled.div`
  background-color: #fff;
  box-shadow: 0px -12px 16px rgba(0, 0, 0, 0.04);
  padding: 16px;
  display: flex;
  justify-content: end;
  & > *:not(:last-of-type) {
    margin-right: 16px;
  }
`
