import styled from 'styled-components'

export default function Hamburger({ ...props }) {
  return (
    <HamburgerContainer {...props}>
      <div></div>
      <div></div>
      <div></div>
    </HamburgerContainer>
  )
}

const HamburgerContainer = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
  background-color: #fff;
  & > div {
    width: 24px;
    height: 2px;
    border-radius: 4px;
    background-color: var(--grayAsh);
    position: absolute;
    &:nth-of-type(1) {
      top: 4px;
      transition:
        transform 0.5s,
        width 0.5s;
    }
    &:nth-of-type(2) {
      top: 11px;
      transition:
        transform 0.5s,
        width 0.5s;
      transform-origin: center;
    }
    &:nth-of-type(3) {
      top: 18px;
      transition:
        transform 0.5s,
        width 0.5s;
    }
  }
  &.active {
    & > div {
      &:nth-of-type(1) {
        transform: rotate(45deg) translate(5px, 5px);
      }
      &:nth-of-type(2) {
        transform: scale(0);
      }
      &:nth-of-type(3) {
        transform: rotate(-45deg) translate(5px, -5px);
      }
    }
  }
`
